<template>
  <li ref="itemRefs" :class="[$style.stepperItem, { [$style.active]: active }]">
    <slot></slot>
  </li>
</template>

<script setup lang="ts">
import { watch, ref } from "vue"

const itemRefs = ref<HTMLElement>()

const props = withDefaults(
  defineProps<{
    active?: boolean
  }>(),
  {
    active: false,
  },
)

watch(
  () => props.active,
  (newVal) => {
    if (newVal) {
      // activeなステップにスクロール
      itemRefs.value?.scrollIntoView({ behavior: "smooth", block: "nearest" })
    }
  },
)
</script>

<style scoped module lang="scss">
.stepperItem {
  flex: 1;
  position: relative;
  padding-right: 1rem;
  list-style-type: none;
  text-transform: uppercase;
  color: $primary-40;
  white-space: nowrap;
  text-align: left;
  &:after {
    position: absolute;
    bottom: -1rem;
    left: 0;
    width: 100%;
    height: 2px;
    border-radius: 2px;
    content: "";
    background-color: $primary-20;
  }
  &.active {
    color: $primary;
  }
  &.active:after {
    background-color: $primary;
  }
}
</style>
